import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#f34c57",
        accent: "#0d6efd",
        secondary: "#8A8D93",
        success: "#30D988",
        info: "#16B1FF",
        warning: "#EB5757",
        error: "#FF4C51",
      },
    },
  },
});
