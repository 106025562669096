import Vue from "vue";
import "@/styles/styles.css";
import "./plugins/vue-composition-api";
import vuetify from "./plugins/vuetify";

import App from "./App.vue";
import router from "./router";

// Import vuetify confirm here instead of plugin file
// to pass vuetify instance to it
import VuetifyConfirm from "vuetify-confirm";
Vue.use(VuetifyConfirm, { vuetify });

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
