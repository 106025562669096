import Vue from "vue";
import VueRouter from "vue-router";
import { useRouter } from "@/composables/router";
const { setRoute } = useRouter();
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "dashboard",
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/Dashboard.vue"),
    meta: {
      layout: "content",
    },
  },
  {
    path: "/manage-assignment",
    name: "manage-assignment",
    component: () => import("@/views/Assignments.vue"),
    meta: {
      layout: "content",
    },
  },
  {
    path: "/accounts",
    name: "accounts",
    component: () => import("@/views/Accounts.vue"),
    meta: {
      layout: "content",
    },
  },
  {
    path: "/account-archive",
    name: "archive",
    component: () => import("@/views/AccountArchive.vue"),
    meta: {
      layout: "content",
    },
  },
  {
    path: "/add-user",
    name: "addVaAccount",
    component: () => import("@/views/AddVaAccount.vue"),
    meta: {
      layout: "content",
    },
  },
  {
    path: "/manage-subscriptions",
    name: "manage-subscriptions",
    component: () => import("@/views/ManageSubscription.vue"),
    meta: {
      layout: "content",
    },
  },
  {
    path: "/conversations",
    name: "conversations",
    component: () => import("@/views/Conversations.vue"),
    meta: {
      layout: "content",
    },
  },
  {
    path: "/support",
    name: "support",
    component: () => import("@/views/SupportMessage.vue"),
    meta: {
      layout: "content",
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/Settings.vue"),
    meta: {
      layout: "content",
    },
  },
  {
    path: "/manage-pricing",
    name: "manage pricing",
    component: () => import("@/views/Pricing.vue"),
    meta: {
      layout: "content",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
    meta: {
      layout: "blank",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _, next) => {
  setRoute(to);
  const accessToken = localStorage.getItem("accessToken");

  // Redirect to login if not logged in
  if (!accessToken && to.meta.requiresAuth) return next({ name: "auth-login" });

  return next();
});

export default router;
